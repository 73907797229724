<template>
    <div class="sf__container">
        <template v-if="results.length > 0">
            <div style="display: flex">
            <Filters
                :global-selections="selections"
                :foam="isFoam()"
                :vehicle="vehicle"
                :popup="popup"
                :results="results"
                @onApply="applyFilters"
                :show-filters="showFilters"
                @closeFilters="showFilters = false"
                />
            <Results
                @updateFilters="showFilters = true"
                :vehicle="vehicle"
                :vehicle-id="vehicle.id"
                v-if="!popup"
                :results="filtered_results"
                :foam="isFoam()"
            />
            </div> 
        </template>
        <!-- <Results  /> -->
    </div>
</template>


<script>
import Filters from './Filters.vue'
import Results from './Results.vue'
import Searcher from '../helpers/searcher'; 
import Part from '../models/Part'; 
import Result from '../models/Result'; 
import Vehicle from '../models/Vehicle'; 
import store from '../store'; 
import Storage from '../helpers/storage'; 
import {slugify} from '../helpers/helpers'; 

export default {

    props: ['slug', 'foam'], 

    components: {
        Filters, 
        Results
    }, 

    data() {
        return {
            products_loaded: false, 
            popup: true,
            showFilters: false, 
            results: [], 
            selections: {}, 
            ignored: [], 
            vehicle: null
        }
    }, 

    async mounted() {

        const urlParams = new URLSearchParams(window.location.search);
        const params = {
            year: urlParams.get('year'), 
            make: urlParams.get('make'), 
            model: urlParams.get('model'), 
            submodel: urlParams.get('submodel')
        }

        // Here we could paginate and load everything. 
        const {hits} = await Searcher.search( this.isFoam() ? 'foam durofoam' : '*', {
            optionalWords: this.isFoam() ? 'foam durofoam' : null, 
            filters: Searcher.buildFilters( params ), 
            hitsPerPage: 2000
        });
        this.results = hits.map( hit => new Result(hit, new Vehicle(hit.vehicle)));

        this.vehicle = this.results[0].vehicle; 

        store.results = this.results; 
        store.vehicle = this.vehicle; 
        store.collection = slugify(
            Object.values(params).join('-')
        );

        this.selections = this.loadPreviousSelections(); 
    }, 

    computed: {
        filtered_results() {

            // If not items have been selected, we just return empty. 
            let not_all_selected = Object.keys(this.selections).length > 0 
                && Object.keys( this.selections ).some( key => {
                    return [null, ''].includes(this.selections[key])
                        && !this.ignored.includes(key); 
                }); 

            if ( not_all_selected ) {
                return []; 
            }

            return this.results.filter( result => {
                for (const key in this.selections) {
                    // Here it's ok to skip empty options because we need to show them anyways. 
                    if ( !result.optionMatches(key, this.selections[key]) && !result.optionIsEmpty(key) ) {
                        return false; 
                    }
                }

                return true; 
            }).map( result => {
                let part = new Part(result); 
                    // part.setVehicle( this.vehicle ); 
                return part; 
            } );
        },

        block_body() {
            return this.popup || this.showFilters; 
        }
    }, 

    watch: {
        block_body(block) {
            const body = document.querySelector('body');
            if ( block ) {
                body.style.position = 'fixed';
                body.style.height = '100%'; 
            } else {
                body.style.position = '';
                body.style.height = ''; 
            }
        }
    }, 

    methods: {
        applyFilters(data) {
            // Adds relevant information to history state
            const queryString = new URLSearchParams(document.location.search);
            Object.keys(data.selections).forEach(
                key => queryString.set(key, data.selections[key]),
            );
            history.pushState(null, null, '?' + queryString.toString());

            this.selections = data.selections;
            this.ignored = data.ignored;  
            this.popup = false; 

            this.storeSelectionsInStorage(); 
        }, 

        getSlug() {
            if ( this.slug ) {
                return this.slug; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('slug') || null; 
        }, 

        isFoam() {
            if ( this.foam ) {
                return true; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('foam') == 'true' ? true : false; 
        }, 

        /**
         * We store selections in sessionStorage to have them available in the future. 
         */
        storeSelectionsInStorage() {
            Storage.storeSelections(store.collection, this.selections); 
        }, 

        /**
         * Allows us to pull selections from storage to show them back as they were. 
         */
        loadPreviousSelections() {
            let keys = Object.keys(this.results[0].options); // this gives us all options from a result in algolia. 
            let selections = {}; 

            // Here we only match those in the keys. 
            ( new URLSearchParams(window.location.search)).forEach( (value, key) => {
                if ( keys.includes(key)) {
                    selections[key] = value; 
                }
            });

            if ( Object.keys(selections).length > 0) {
                return selections; 
            }

            // Otherwise we pull the storage selections. 
            const storage_selections = Storage.getSelections(store.collection); 
            return ( !storage_selections ) ? {} : storage_selections; 
        }
    }
}
</script>