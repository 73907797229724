class Option {

    constructor(data) {
        this.buildFromApi(data); 
    }

    buildFromApi(data) {
        this.name = data.name;
        this.label = data.label; 
        this.slug = data.slug; 
        this.image = data.image; 
        this.values = data.values.filter( val => val.value != '');
        this.has_image = data.has_image; 
        this.description = data.description || null;
        this.type = data.paired_with; 
    }
}

export default Option;