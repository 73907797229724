import Ecommerce from '../adapters/Ecommerce';
import Api from '../helpers/api'; 
import store from '../store'; 
import Storage from '../helpers/storage'; 

const Platform = Ecommerce.getInstance( window.sf_options.platform ); 

class Part {
    constructor(result) {
        if ( result != null ) {
            this.buildFromResult( result ); 
        }
    }

    buildFromResult(result) {
        this.build_id = result.objectID;
        this.id = result.part.id;
        this.price = parseFloat(result.part.retail_price), // this.money(result.part.retail_price);
        this.sku = result.part.sku;
        this.price_sale = this.discountPrice(parseFloat(result.part.retail_price));
        this.image = result.part.additional_data.image_filename_1;
        this.row = result.options.row;
        this.side = result.options.side;
        this.part = result.options.part;
        this.options = result.options;
        this.install_videos = Object.keys(result.part.additional_data).filter(key => {
            return key.includes('install_video_url') && result.part.additional_data[key] != ''; 
        }).map( key => result.part.additional_data[key] );
        this.setVehicle( result.vehicle); 
    }

    buildFromStorage(part) {
        Object.assign(this, part); 
        return this; 
    }

    /**
     * We will use this to set the vehicle information if we need it. 
     * @param {*} slug 
     */
    setVehicle(vehicle) {
        this.vehicle = vehicle; 
    }

    discountPrice(value) {
        if ( !value ) return null; 
        return this.applyDiscounts(value); 
    }

    applyDiscounts(value) {
        const discount = window.sf_options?.discount || 0; 
        return (discount > 0)
            ? parseFloat(value) * 1.0 * (100 - discount) / 100.0
            : parseFloat(value) * 1.0; 
    }

    /**
     * This should allow us to view the part. Like generating an url and going to it. 
     */
    async view() {
        Platform.buildProductUrl(this.vehicle, this.build_id).then( url => { 
            this.storeOptionsInStorage(); 

            let selections = Storage.getSelections(store.collection); 
            let querystring = (selections) ? (new URLSearchParams(selections)).toString() : ''; 

            window.location.href = url + '?' + querystring; 
        }); 
    }
    

    /**
     * Add to cart. 
     */
    async addToCart() {

        // This is to pull the collection from the options, but if it's not set, we use the vehicle tag. 
        const collection = window.sf_options.collection
            ?? this.vehicle.tag 

        let response = await Platform.addToCart(this.build_id, 1, collection); 
        return response; 
    }


    storeOptionsInStorage() {

        // We store these values in sessionStorage so then we can use them in the page when 
        // visiting, to load a few values via js, and without calling the API. 
        let storage_options = {
            vehicle_tag: {
                label: 'Vehicle Tag', 
                value: this.vehicle.tag
            }, 
            vehicle: {
                label: 'Vehicle', 
                value: this.vehicle.collection_name
            }
        }; 

        for (const key in this.options) {
            if ( this.options[key] != null && this.options[key] != '') {
                let label = store.filters.filter(f => f.slug == key); 
                
                storage_options[key] = {
                    label: label.length > 0 ? label[0].label : key.split('_').map((word) => { 
                            return word[0].toUpperCase() + word.substring(1); 
                        }).join(" "), 
                    value: this.options[key]
                }; 
            }
        }
        
        Storage.storePartOptions(this.build_id, storage_options); 
    }
}


export default Part; 