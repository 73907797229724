import {reactive} from 'vue'; 
/**
 * We use this small store object to prevent using something like 
 * Vuex which adds a ton of extra behavior we don't need and makes 
 * scripts heavier. 
 */
export default reactive({
    environment: process.env.SEATSHOP_ENVIRONMENT, 
    results: [], // This contains the whole list of results for a given YMMS
    results_page: [], // This contains the last page of results. 
    vehicle: {},  
    filters: [],
    collection: null, // the slug of the current vehicle. 
    products: {}, 
    alerts: {},
}); 