<template>
    <div class="seatshop__loader__container">
        <div class="seatshop__loader"></div>
    </div>
</template>

<script>
export default {

}
</script>