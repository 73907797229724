<template>
    <div class="sf__results">
        <button class="sf__filters__update-filters-button" @click="editSelection">Edit Selection</button>
        <!-- <h1>{{ vehicle.collection_name }}</h1> -->

        <div v-if="results.length < 1">
            <p>There are no results matching your search. Please try modifying filters to find matching products.</p>
        </div>

        <!-- So the first filter is tabs. ... the second filter is the one that goes in -->
        <template v-else>
            <div class="sf__results__alerts" v-if="Object.values(alerts).filter(a => a).length > 0 && foam">
                <template v-for="(alert, key) in alerts" :key="key">
                    <div v-if="alert" class="sf__result__alert">
                        <img class="sf__result__alert__image" v-if="alert.image" :src="alert.image">
                        <div class="sf__result__alert__content">
                            <p>{{ alert.label }}</p>
                            <p class="sf__result__alert__mesage" v-html="alert.message"></p>
                        </div>
                    </div>
                </template>
            </div>


            <div class="sf__results__tabs">

                <!-- Tabs List -->
                <div class="sf__result__tabs__list">
                    <div v-for="(tab, i) in tabs" :key="i" v-bind:class="{ 'active': current_tab == i }" @click="current_tab = i">
                        {{ tab }}
                    </div>
                </div>

                <!-- Shortcuts within each tab. -->
                <template v-for="(tab, i) in tabs" :key="i">
                    <div v-if="!pulling_plaform_results && filterResults({row: tab}).length > 0 && i == current_tab">

                        <!-- And here we add the quick access if the level 1 filter values is greater than 1. -->
                        <div class="sf__result__shortcuts">
                            <template v-for="(side, j) in getSides(filterResults({row: tab}), tab)" :key="j">
                                <a :href="`#filter-${i}-${j}`" v-if="filterResults({row: tab, side: side}).length > 0">{{ side }}</a>
                            </template>
                        </div>

                        <!-- This is basically the tab -->
                        <template v-for="(side, j) in getSides(filterResults({row: tab}), tab)" :key="j">
                            <div v-if="filterResults({row: tab, side: side}).length > 0">
                                <h4 class="sf__result__section__title" :id="`filter-${i}-${j}`">{{ tab }} - {{ side }}</h4>
                                <ResultsList :parts="filterResults({
                                    row: tab, 
                                    side: side
                                })" />
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import ResultsList from './ResultsList.vue'; 
import store from '../store'; 
import Api from '../helpers/api'; 
import Storage from '../helpers/storage';
import Ecommerce from '../adapters/Ecommerce'; 

const Platform = Ecommerce.getInstance(window.sf_options.platform); 


export default {
    props: ['results', 'vehicle', 'foam'],

    components: {
        ResultsList, 
    }, 

    watch: {
        results( newVal ) {
            // We store this so we can recommend after. 
            store.results_page = newVal; 
            this.updateProductData(); 
        }
    }, 

    mounted() {
        // We store this so we can recommend after. 
        store.results_page = this.results; 
        this.updateProductData(); 
    }, 


    computed: {
        alerts() {
            return store.alerts; 
        }
    }, 


    data() {
        const tabs = this.getRows(this.results); 
        return {
            tabs: tabs,
            current_tab: 0, 
            filters: [], 
            filter_tabs: null, 
            filter_level_1: null, 
            active_tab: 1,
            product_data: {},

            // We use this flag to know if this is pulling results from 
            // The CRM platform before showing, so we show everything 
            // after we have all the information.
            pulling_plaform_results: false
        }
    }, 

    methods: {

        getValuesOrder(slug) {
            return store.filters.find( filter => filter.slug == slug )
                .values.map( value => value.value ); 
        }, 

        getRows(parts) {
            return this.sortByOrder(parts.reduce((carry, res) => {
                if ( !carry.includes(res.row) ) carry.push(res.row); 
                return carry;
            }, []), this.getValuesOrder('row')); 
        }, 

        getSides(parts, row) {
            return this.sortByOrder(parts.reduce((carry, res) => {
                if ( res.row == row && !carry.includes(res.side) ) {
                    carry.push(res.side); 
                }
                return carry;
            }, []), this.getValuesOrder('side'));
        },

        sortByOrder(array, order) {
            return array.sort( (a, b) => {
                let a_index = order.findIndex( word => word == a ); 
                let b_index = order.findIndex( word => word == b ); 
                return a_index - b_index; 
            } ); 
        }, 

        /**
         * Filtering based on a selection of filters we create. 
         */
        filterResults( filters ) {

            const results_order = this.getValuesOrder('part'); 
            return this.results.filter (res => {
                let valid = true; 

                // We want to allow null options
                Object.keys(filters).forEach ( key => {
                    if ( res[key] != null && filters[key] != res[key] ) {
                        valid = false; 
                        return false; // break.  
                    }
                }); 

                return valid; 

            }).sort((a, b) => {
                let a_index = results_order.findIndex( value => a.part == value ); 
                let b_index = results_order.findIndex( value => b.part == value ); 
                return a_index - b_index; 

            // We want foam to appear first. 
            }).sort((a, b) => {
                return (
                    !a.part.toLowerCase().includes('foam')
                    && b.part.toLowerCase().includes('foam')
                ) ? 1 : 0;  
            }); 
        }, 

        editSelection() {
            // Removes selections from the URL
            const currentSelections = Storage.getSelections(store.collection);
            const queryString = new URLSearchParams(document.location.search);
            Object.keys(currentSelections).forEach(
                key => queryString.delete(key),
            );
            history.pushState(null, null, '?' + queryString.toString());

            Storage.removeSelections( store.collection ); 
            window.location.reload(); 
        }, 

        async updateProductData() {
            this.pulling_plaform_results = true;

            let products = Storage.getProducts(); 

            if (!products) {
                products = {}; 
            }

            let ids = this.results.map ( result => result.build_id )
                .filter( id => products[id] == null );

            if ( ids.length > 0 ) {
                let {data} = await Api.getProducts( ids ); 

                data.shopify_products.forEach ( product => {
                    products[product.build_id] = Platform.buildProduct( product ); 
                }); 
            }

            Storage.storeProducts( products ); 
            store.products = products; 

            this.pulling_plaform_results = false;
        }
    }
}
</script>