<template>
    <div class="sf__part-exceptions" v-if="exception">
        <img v-if="exception_image" :src="exception_image" alt="Exception Image">
        <h3>Exception</h3>
        <p>{{ exception }}</p>
    </div>
</template>

<script>
import Storage from '../helpers/storage'; 

export default {
    props: ['slug', 'buildId'], 

    data() {
        return {
            exception: null, 
            exception_image: null, 
        }
    }, 

    mounted() {
        let details = this.getDetails(); 
        if ( details.exception != null ) {
            this.exception = details.exception.value; 
        }

        if ( details.exception_image != null ) {
            this.exception_image = details.exception_image.value; 
        }
    },

    methods: {
        getSlug() {
            if ( this.slug ) {
                return this.slug; 
            }

            if ( window.sf_options.collection ) {
                return window.sf_options.collection; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('slug'); 
        },  

        getBuildId() {
            if ( this.buildId ) {
                return this.buildId; 
            }

            if ( window.sf_options.build_id ) {
                return window.sf_options.build_id; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('build_id');  
        }, 

        getDetails() {
            return Storage.getPartOptions( this.getBuildId() ); 
        }
    }
}
</script>