<template>
    <div class="sf__part-recommendations" v-if="products.length > 0">
        <h2>Product Recommendations</h2>
        <ResultsList :parts="selected_products" :take="limit" />
    </div>
</template>

<script>
import PartModel from '../models/Part';
import Part from './Part.vue'; 

import ResultsList from './ResultsList.vue'; 
import { shuffle, uniqBy } from 'lodash'; 
import Storage from '../helpers/storage'; 
import store from '../store'; 
import Api from '../helpers/api'; 
import Ecommerce from '../adapters/Ecommerce'; 

const Platform = Ecommerce.getInstance(window.sf_options.platform); 

export default {
    props: ['slug', 'buildId'], 

    components: {
        Part, ResultsList
    }, 

    data() {
        return {
            products: [], 
            current_build_ids: [],
            limit: 4
        }
    }, 

    mounted() {

        // This is to pull all build ids from product pages, or cart items. 
        this.current_build_ids = []
            .concat( this.cartBuildIds() )
            .map( id => parseInt(id)); 

        if ( this.getBuildId() ) {
            this.current_build_ids.unshift( this.getBuildId() );
        }

        const products = this.getProducts(); 

        if ( products.length < 1) return; 

        // We do this to keep dragging recommendations. 
        store.vehicle = products[0].vehicle; 
        store.results_page = products; 
        store.collection = this.getSlug(); 

        this.products = products; 

        this.updateProductData(); 
    },

    computed: {
        selected_products() {
            let matches = []; 

            this.products.filter( product => {
                return this.current_build_ids.includes(parseInt(product.build_id)); 
            }).forEach( product => {
                matches.push({
                    'row': product.row, 
                    'recommended_by': product.recommended_by
                });  
            });

            return shuffle(
                this.products.filter( product => {
                    return !this.current_build_ids.includes( parseInt(product.build_id) )
                        && matches.some( match => {
                            return match.row === product.row && match.recommended_by === product.recommended_by;
                        }); 
                })
            ); 
        }
    }, 

    methods: {
        getSlug() {
            if ( this.slug ) {
                return this.slug; 
            }

            if ( window.sf_options.collection ) {
                return window.sf_options.collection; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('slug'); 
        },  

        getBuildId() {
            if ( this.buildId ) {
                return this.buildId; 
            }

            if ( window.sf_options.build_id != null ) {
                return window.sf_options.build_id; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('build_id');  
        }, 

        cartBuildIds() {
            return window.sf_options?.cart_builds.map (build => build.id ) || []; 
        }, 

        getProducts() {
            let all_products = []; 
            this.current_build_ids.forEach(id => {
                let products = Storage.getRecommendations(id); 

                if ( !products ) {
                    return; 
                }

                // this is to know where each product comes from, so we can 
                // adjust recommendations. 
                products.forEach( product => {
                    product.recommended_by = id; 
                });

                all_products = all_products.concat( products ); 
            }); 

            all_products = uniqBy(all_products, 'build_id');

            return all_products.map( part => (new PartModel).buildFromStorage(part)); 
        }, 

        async updateProductData() {
            let products = Storage.getProducts(); 

            if (!products) {
                products = {}; 
            }

            let ids = this.products.map ( result => result.build_id )
                .filter( id => products[id] == null );
            
            if ( ids.length > 0 ) {
                let {data} = await Api.getProducts( ids ); 

                data.shopify_products.forEach ( product => {
                    products[product.build_id] = Platform.buildProduct( product ); 
                }); 
            }

            Storage.storeProducts(products); 
            store.products = products; 
        }
    }
}
</script>