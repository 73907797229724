import store from '../store'; 

const Local = {

    goToPartResults(handle, options) {
        let url = '/collection.html?slug=' + handle; 

        if ( options ) {
            url += '&' + (new URLSearchParams(options)).toString()
        }

        window.location.href = url; 
    }, 

    buildProductUrl(vehicle, build_id) {
        let handle = store.products[build_id].handle; 
        let collection = store.collection;

        return new Promise( resolve => {
            resolve(`part.html?slug=${collection}&build_id=${build_id}&handle=${handle}`); 
        }); 
    }, 

    addToCart: async (build_id, quantity, slug) => {
        console.log('Fake: adding product to cart');

        let response = await new Promise( resolve => {
            setTimeout( () => { console.log('fake: processing'); resolve({
                build_id: build_id,
                quantity: quantity,
                properties: {_seatshop: 1, _collection: slug}
            }); }, 1000);
        });

        return response; 
    }, 

    // This is a fake sleep function
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }, 

    buildProduct(data) {
        let variant = data.variants[0]; 

        return {
            handle: data.handle, 
            title: data.title,
            id: variant.id, 
            available: variant.inventory_management != 'shopify' || variant.inventory_policy != 'deny' || variant.inventory_quantity > 0,
            eligible: !data.tags.toLowerCase().split(', ').includes('not-eligible'), 
            image: data.image?.src != null ? data.image.src.replace(/\.(jpe?g|png|gif)\?/i, '_600x.$1?') : null
        }
    }
}

export default Local;