/**
 * this helper contains the endpoints required to hit the Seat Shop filters api. 
 * This uses a public api so no authentication is required. 
 */
const axios = require('axios').create(); 
axios.defaults.baseURL = process.env.SEATSHOP_API + '/api'; 
const Api = {
    getOptions: (params) => {
        return axios.get('options', {
            params: params
        }); 
    }, 


    getVehicle: (params) => {
        return axios.get('vehicles', {
            params: params
        })
    }, 

    getProducts: (ids) => {
        return axios.get('shopify/products', {
            params: {
                builds: ids.join(',')
            }
        }); 
    }
}

export default Api; 