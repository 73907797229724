<template>
    <div class="sf__filters__section sf__filters__summary" v-if="shouldDisplaySummary()">
        <h1 v-if="section_filters.length == 1">{{ section_filters[0].label }}</h1>
        <h1 v-else>{{ section.title }}</h1>
        <ul>
            <li :key="key" v-for="f, key in section_filters">
                <img :src="f.image" :alt="f.value">
                <span>
                    <strong>{{ f.label }}</strong>
                    {{ f.value }}
                </span>

                <div class="sf__alert" v-if="foam && f.alert != null">
                    <p v-html="f.alert"></p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['filters', 'selections', 'section', 'foam', 'alerts'], 

    computed: {

        section_filters() {
            let selections = {}; 
            this.filters.forEach( filter => {

                // we build from the alert. 
                if ( this.alerts[filter.slug] ) {
                    // we build from the alert. 
                    selections[filter.slug] = {
                        label: filter.label || filter.slug.replace(/_/g, ' '), 
                        value: this.alerts[filter.slug].value, 
                        image: this.alerts[filter.slug].image, 
                        alert: this.alerts[filter.slug].message
                    }

                } else if ( this.selections[filter.slug] != null ) {
                    // we build from the selection. 
                    selections[filter.slug] = {
                        label: filter.label || filter.slug.replace(/_/g, ' '), 
                        value: this.selections[filter.slug], 
                        image: filter.values
                            .filter( value => value.value == this.selections[filter.slug])[0].image || null
                    }; 
                }
            }); 

            return selections; 
        }
    }, 

    methods: {
        shouldDisplaySummary() {
            let withRegularSelections = Object.values(this.section_filters).filter( filter => filter.alert == null ).length > 0; 
            let withAlerts = Object.values(this.section_filters).filter( filter => filter.alert != null ).length > 0; 

            return withRegularSelections || this.foam && withAlerts; 
        }
    }
}
</script>