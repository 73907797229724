const axios = require('axios').create(); 
import store from '../store'; 
/**
 * The Shopify adapter responds to the factory used by the platform. 
 */
const Shopify = {

    goToPartResults(handle, options) {

        let url = `/collections/${handle}/?results=1`; 

        if ( options ) {
            url += '&' + (new URLSearchParams(options)).toString()
        }

        window.location.href = url; 
    }, 

    buildProductUrl(vehicle, build_id) {
        let handle = store.products[build_id].handle;
        let collection = store.collection;

        return new Promise( resolve => {
            if ( vehicle != null ) {
                resolve(`/collections/${collection}/products/${handle}`); 
            } else {
                resolve(`/products/${handle}`); 
            }
        }); 
    }, 

    addToCart: async (build_id, quantity, slug) => {
        let variant_id = store.products[build_id].id;

        let {data} = await axios.post('/cart/add.js', {
            id: variant_id, 
            quantity: quantity,
            properties: { _seatshop: 1, _collection: slug}
        }); 

        return data; 
    }, 

    buildProduct(data) {
        let variant = data.variants[0]; 

        return {
            handle: data.handle, 
            title: data.title,
            id: variant.id, 
            available: variant.inventory_management != 'shopify' || variant.inventory_policy != 'deny' || variant.inventory_quantity > 0, 
            eligible: !data.tags.toLowerCase().split(', ').includes('not-eligible'),
            image: data.image?.src != null ? data.image.src.replace(/\.(jpe?g|png|gif)\?/i, '_600x.$1?') : null
        }
    }

}

export default Shopify;