export default {

    /**
     * Sets recommendations for a specific build
     * @param {*} build_id 
     * @param {*} values 
     * @returns 
     */
    storeRecommendations(build_id, values) {
        return sessionStorage.setItem(`${build_id}-recommendations`, JSON.stringify(values)); 
    }, 

    /**
     * Gets Build specific recommendations
     * @param {*} build_id 
     * @returns 
     */
    getRecommendations(build_id) {
        let values = sessionStorage.getItem(`${build_id}-recommendations`); 

        if ( !values ) {
            return null; 
        }

        return JSON.parse( values ); 
    }, 

    
    storeProducts(products) {
        return sessionStorage.setItem(`ecommerce-products`, JSON.stringify(products)); 
    }, 


    getProducts(build_id) {
        let products = sessionStorage.getItem(`ecommerce-products`); 

        if ( !products ) {
            return null; 
        }

        return JSON.parse( products ); 
    }, 


    storeSelections(slug, values) {
        return sessionStorage.setItem(`${slug}-selections`, JSON.stringify(values)); 
    }, 


    getSelections(slug) {
        let values = sessionStorage.getItem(`${slug}-selections`); 

        if ( !values ) {
            return null; 
        }

        return JSON.parse( values ); 
    }, 

    removeSelections(slug) {
        sessionStorage.removeItem(`${slug}-selections`); 
    }, 


    storePartOptions(build_id, options) {
        sessionStorage.setItem(`${build_id}-options`, JSON.stringify(options)); 
    }, 

    getPartOptions(build_id) {
        let options = sessionStorage.getItem(`${build_id}-options`); 

        if ( !options ) {
            return null; 
        }

        return JSON.parse( options );
    }, 

    storeLastVehicleSelection(selection) {
        sessionStorage.setItem(`last-vehicle-selection`, JSON.stringify(selection)); 
    }, 


    getLastVehicleSelection() {
        let selection = sessionStorage.getItem(`last-vehicle-selection`); 

        if ( !selection ) {
            return null; 
        }

        return JSON.parse( selection );
    }    
}