import { createApp, h } from 'vue'
import PartResults from './components/PartResults.vue'; 
import PartDetails from './components/PartDetails.vue'; 
import PartRecommendations from './components/PartRecommendations.vue'; 
import PartExceptions from './components/PartExceptions.vue'; 
import Vehicles from './components/Vehicles.vue'; 
import InstallVideos from './components/InstallVideos.vue'; 
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const mainApp = createApp({})
mainApp.component('PartResults', PartResults ); 
mainApp.component('Vehicles', Vehicles ); 
mainApp.component('PartDetails', PartDetails ); 

const installvideos = createApp(InstallVideos)
const productDetails = createApp(PartDetails)
const productRecommendations = createApp(PartRecommendations)
const productExceptions = createApp(PartExceptions)

// Sentry.init({
//     app: [
//       mainApp,
//       installvideos,
//       productDetails,
//       productRecommendations,
//       productExceptions
//     ],
//     dsn: "https://86faaf2125c44c49b90924e4e4fb189b@o64802.ingest.sentry.io/5943669",
//     integrations: [
//       new Integrations.BrowserTracing({}),
//     ],
//     tracesSampleRate: process.env.SENTRY_SAMPLE_RATE,
//     environment: process.env.SEATSHOP_ENVIRONMENT || 'development', 
//     trackComponents: true,
//     logErrors: true, 
//     release: 'seatshop-filters@' + process.env.npm_package_version
// });

if ( document.getElementById('seat-shop-filters')) {
  mainApp.mount('#seat-shop-filters'); 
}

if ( document.getElementById('seatshop-install-videos')) {
  installvideos.mount('#seatshop-install-videos'); 
}

if ( document.getElementById('seatshop-product-details')) {
  productDetails.mount('#seatshop-product-details'); 
}

if ( document.getElementById('seatshop-product-recommendations')) {
  productRecommendations.mount('#seatshop-product-recommendations');
}

if ( document.getElementById('seatshop-product-exceptions')) {
  productExceptions.mount('#seatshop-product-exceptions');
}


/**
 * This snippet allows mounting multiple instances of the ymms selector on differnet pages. 
 */
let ymms_selectors = document.getElementsByClassName('seatshop-ymms-selector'); 
for ( let mountEl of ymms_selectors) {
    let ymms = createApp({
      render: () => {
        return h(Vehicles, { ...mountEl.dataset });
      }
    }).mount(mountEl);
}