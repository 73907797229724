<template>
    <div class="sf__results__list">
        <template v-for="(name, index) in Object.keys(part_groups)" :key="index">
            <Part v-if="part_groups[name].length == 1" :part="part_groups[name][0]" />
            <PartGroup v-else :parts="part_groups[name]" :name="name" />
        </template>
    </div>
</template>

<style lang="scss">
    .sf__part-recommendations .sf__results__result:nth-child(n+5) {
        display: none;
    }
</style>

<script>
import Part from './Part.vue'; 
import PartGroup from './PartGroup.vue'; 

export default {
    props: ['parts', 'limit'], 

    components: {
        Part, 
        PartGroup
    }, 

    computed: {
        part_groups() {
            let groups = {}; 

            this.parts.forEach( part => {
                let key = `${part.row}-${part.side}-${part.part}`
                if ( groups[key] == null ) {
                    groups[key] = []; 
                }

                groups[key].push(part); 
            }); 

            return groups; 
        }
    }
}
</script>