/**
 * This helper contains everything used to search in algolia. 
 */
 import algoliasearch from 'algoliasearch/lite';

 // Starting the Algolia Index
 const AlgoliaClient = algoliasearch(
     process.env.ALGOLIA_APP_ID,
     process.env.ALGOLIA_SEARCH_ID
);
 const AlgoliaIndex = AlgoliaClient.initIndex(process.env.ALGOLIA_INDEX); 

 const Searcher = {
    search: (query, params = {}) => {
        // This method should probably loop through all results so we get everything we need 
        return AlgoliaIndex.search(query, params); 
    }, 

    searchFacet: (facetName, facetQuery, params) => {
        return AlgoliaIndex.searchForFacetValues(facetName, facetQuery, params); 
    }, 

    /**
     * This method builds the filters to make the new search. 
     */
    buildFilters: (values) => {
        let filters = ['active:true']; 
        
        if ( values.year != '' ) {
            filters.push(`vehicle.start_year<=${values.year} AND vehicle.end_year>=${values.year}`); 
        }

        if ( values.make != '' ) {
            filters.push(`vehicle.make:'${values.make}'`); 
        }

        if ( values.model != '' ) {
            filters.push(`vehicle.model:'${values.model}'`); 
        }

        if ( values.submodel != '' ) {
            filters.push(`vehicle.submodel:'${values.submodel}'`); 
        }

        return filters.join(' AND '); 
    },
 }

 export default Searcher; 