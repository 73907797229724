import {slugify} from '../helpers/helpers'; 

class Vehicle {

    constructor(data) {
        Object.assign(this, data); 

        // We will use this to have a global name that we can 
        // reuse in other interfaces. 
        this.general_name = this.getVehicleName(); 
        this.general_slug = slugify( this.general_name ); 
    }

    getVehicleName(year) {
        let params = []; 

        if ( typeof year !== 'undefined' ) {
            params.push(year); 
        } else {
            params.push(
                `${this.start_year}${ ( this.start_year != this.end_year ) ? '-' + this.end_year  : ''}`
            )
        }

        params = params.concat([
            this.make, 
            this.model, 
            this.submodel, 
            this.class
        ]); 

        return params.join(' ').trim(); 
    }

}

export default Vehicle;