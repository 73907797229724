<template>
    <p class="sf__filters__tooltip__trigger" >
        <a @click.prevent="opened = !opened">Need Help?</a>
    </p>
    <teleport to="body">
        <transition name="fade">
            <div class="sf__filters__tooltip" v-if="opened" @click.prevent="opened = false">
                <div class="sf__filters__tooltip__inner">
                    <h5>{{ filter.label }}</h5>
                    {{ filter.description }}
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>export default {
    props: ['filter'], 

    data() {
        return {
            opened: false
        }
    }
}</script>