<template>
    <div class="sf__parts__modal" @click="closeModal">
        <div class="sf__parts__modal__inner" @click="event => event.stopPropagation()">
            <h3>Viewing Products for: {{parts[0].part}}</h3>
            <div class="sf__parts__modal__list">
                <Part :specific="true" :videos="videos" :group="true" v-for="part in parts" :key="part.id" :part="part" />
            </div>
        </div>
    </div>
</template>

<script>
import Part from './Part.vue'; 

export default {
    props: ['parts', 'videos'], 

    components: {
        Part
    }, 

    methods: {
        closeModal(event) {
            event.stopPropagation(); 
            this.$emit('onClose'); 
        }
    }
}
</script>
