<template>
    <div class="sf__install-video__modal" @click="close">
        <div class="sf__install-video__modal__inner" @click.prevent="(event) => { event.stopPropagation() }">
            <iframe :src="`https://www.youtube.com/embed/${video.id}`" :title="video.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autoplay></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: ['video'], 

    methods: {
        close() {
            this.$emit('close');
        }
    }   
}</script>