/**
 * This class is responsible of building and getting all available options from
 * The results provided by algolia. 
 */
import Api from './api'; 
import Option from '../models/Option'; 
import merges from './merges'; 

class Filters {
    constructor(results) 
    {
        this.results = results; 
        this.options = null; 
    }

    /**
     * This method will build all filters based on the options given by the api. 
     */
    async build( params )
    {
        let options = await Api.getOptions({
            vehicle_id: params.vehicle_id
        }); 

        this.options = options.data.options; 

        let filters = options.data.options
        .sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1) // Sorting by sort order to preserve the api order. 
        .filter( option => {
            return this.results.some( element => element[option.key] !== '' )
            && option.values.filter(val => ![null, ''].includes(val.value)).length > 0
        }).map( filter => {
            return {
                ...filter, 
                ...{values: this.buildValues(filter)} 
            } 
        }).map( data => new Option(data)); 

        return filters; // Here we return a curated list of filters ready to use. 
    }


    /**
     * When we build values, we collect real values from the results, instead of 
     * using the ones coming from the API. This allows us to use images coming from 
     * Algolia. 
     * 
     * @param {} filter 
     * @returns 
     */
     buildValues(filter) {

        let values = []; 

        // We use this plan to group all seat configuration columns into a single 
        // seat configuration, so we can filter all of them using the same initial 
        // filter.
        let keys = [ filter.slug ]; 

        if ( merges[filter.slug] != null ) {
            keys = this.options
                .filter ( option => merges[filter.slug].includes( option.slug ))
                .map( option => option.slug ); 
        }

        this.results.forEach( result => {
            keys.forEach (key => {
                // We skip empty values. 
                const value = result.getOption(key); 

                if ( !value || values.some( f => f.value == value ) ) {
                    return; 
                }

                values.push({
                    value: value, 
                    image: filter.has_image ? result.getOptionImages(key)[value] : {}
                }); 
            })
        });

        // And here we need to sort values to keep their original position. 
        const original_order = filter.values.map ( value => value.value ); 
        return values.sort ( (a, b) => {
            let a_index = original_order.findIndex( word => word == a.value ); 
            let b_index = original_order.findIndex( word => word == b.value ); 
            return a_index - b_index; 
        });
    }

}

export default Filters; 