<template>
    <div class="sf__filters__section" v-bind:class="{
        'sf__filters__section--hide-unavailable': section.sequential
    }">
        <h1>{{ filters.length == 1 ? filters[0].label : section.title }}</h1>

        <template v-for="(filter) in filters" :key="filter.id">
            <div class="sf__filter"
                v-if="activeValues(filter).length > 1"
                v-bind:class="{
                    'sf__filter--selected': selections[filter.slug] != null 
                }"
                >
                <h3 v-if="filters.length > 1"><span>{{ filter.label }}</span></h3>
                <FilterTooltip v-if="filter.description" :filter="filter" />

                <div class="sf__filter__values">
                    <div v-for="(value, j) in activeValues(filter)"
                        :key="j"
                        class="sf__filter__value"
                        @click="selectValue(filter.slug, value.value, false)"
                        v-bind:class="{
                            'sf__filter__value--with-image': filter.has_image,
                            'sf__filter__value--active': isSelected(filter.slug, value.value),
                            'sf__filter__value--unavailable': (
                                (!section.sequential && !firstAvailable(filter.slug, value.value))
                                || (section.sequential && !firstAvailableLinear(filter.slug, value.value))
                            ),
                        }"
                        >
                            <div v-if="filter.has_image" class="sf__filter__value__image" v-bind:style="{ 'background-image': `url(${getFilterValueImage(filter, value)})` }">
                                <span>{{ value.value }}</span>
                            </div>
                            <p class="sf__filter__value__label" v-bind:class="{'sf__filter__value__label--oem': value.value.toLowerCase().includes('oem') }" >{{ value.value.replace('OEM', '').trim() }}</p>
                    </div>
                </div>
            </div>
                <div class="sf__filter"  v-if="activeValues(filter).length == 1 && foam && alerts[filter.slug] != null">
                    <h3><span>{{ filter.label }}</span></h3>
                    <div class="sf__filter__restricted" v-for="(value, index) in activeValues(filter)" :key="index">
                        <div v-if="filter.has_image" class="sf__filter__restricted__image" v-bind:style="{ 'background-image': `url(${value.image})` }"></div>
                        <div class="sf__filter__restricted__content">
                            <p class="sf__filter__restricted__label">{{ value.value }}</p>
                            <div class="sf__alert">
                                <p v-html="alerts[filter.slug].message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        <div class="sf__filters__section__actions" v-if="popup">
            <button v-if="index > minIndex" class="sf__filters__apply" @click="goToPrev()">Back</button>
            <button class="sf__filters__apply" @click="goToNext()" :disabled="!isSelectionComplete()">
                {{ (index == maxIndex) ? `Apply Filters` : `Next` }}
            </button>
        </div>
    </div>
</template>

<script>
import FilterTooltip from './FilterTooltip.vue'; 
import store from '../store'; 

export default {
    props: ['section', 'filters', 'selections', 'results', 'alerts', 'index', 'totalSections', 'minIndex', 'maxIndex', 'foam', 'stopped', 'popup'],

    components: {
        FilterTooltip
    }, 

    mounted() {
        this.init();
    }, 

    computed: {

        // This is to know the selections local to this section. 
        section_selections() {
            let selections = {}; 
            this.filters.forEach ( filter  => {
                if ( typeof this.selections[filter.slug] !== 'undefined') {
                    selections[filter.slug] = this.selections[filter.slug]; 
                }
            }); 

            return selections; 
        },

        // this is to know the count of completed selections. 
        completed() {
            return Object.values(this.section_selections).filter(v => v != null).length; 
        }, 

        available_section_count() {
            return Object.keys(this.section_selections)
                .filter(key => {
                    let filter = this.filters.find(f => f.slug == key ); 
                    let values = this.activeValues(filter); 
                    for (const k in values) {
                        if ( this.firstAvailable(filter.slug, values[k].value) ) {
                            return true; 
                        }
                    }

                    return false; 
            }); 
        }
    },

    methods: {
        init() {
            this.filters.forEach( filter => {
                let values = this.activeValues(filter);

                // if for this filter there's only one value, we store the alert to show 
                // during summary. 
                store.alerts[filter.slug] = ( values.length === 1)
                    ? {
                        label: filter.label || filter.slug.replace(/_/g, ' '), 
                        message: `The results shown are only available for <strong>${values[0].value}</strong>`, 
                        value: values[0].value,
                        image: this.getFilterValueImage(filter, values[0]) || null, 
                    } : null;  

                if (
                    this.selections[filter.slug] != null
                    && values.map ( value => value.value ).includes( this.selections[filter.slug] )
                ) {
                    return; 
                }

                if ( values.length > 1) {
                    this.selectValue(filter.slug, null); 
                } else {
                    this.removeOption(filter.slug); 
                }
            });

            // This is because in some scenarios we might end up without options for 
            // the filter, so what we need to do is just to jump to tne next screen. 
            if ( !this.isOneActiveValueForFilter() || (this.isSelectionComplete() && !this.stopped) ) {
                this.goToNext(); 
            } else {
                this.$emit('stopFilters'); 
            }
        }, 

        /**
         * Is there at least one active value for a given filter?
         */
        isOneActiveValueForFilter() {
            return this.filters.some( filter => {
                return this.activeValues(filter).length > 1; 
            }); 
        }, 

        /**
         * Have all values been selected? 
         */
        isSelectionComplete() {
            // ẃhen all the selected items equals all the available selections. 
            return this.completed === this.available_section_count.length; 
        }, 

        /**
         * Selects a value for that specific slug. 
         */
        selectValue(slug, value, force) {
            force = ( force != null ) ? force : false; 
            this.$emit('select', {slug, value, force}); 
        }, 

        /**
         * Emits an event to remove the slug from 
         * the selections list. 
         */
        removeOption(slug) {
            this.$emit('removeOption', {slug}); 
        }, 

        /**
         * Checks if the given value is selected for that slug. 
         */
        isSelected(slug, value) {
            return this.selections[slug] == value; 
        },

        /**
         * Checks if based on previous selections, there's at least one result 
         * available for that slug and value. 
         */
        firstAvailable(slug, value) {

            return this.results.find( result => {
                for (const key in this.selections) {

                    if ( key != slug && !this.selections[key] ) {
                        continue;
                    } 

                    let compareValue = (key == slug) ? value : this.selections[key]; 
                    
                    if ( !result.optionMatches(key, compareValue)) {
                        return false; 
                    }
                }

                return true; 
            }); 
        },

        /**
         * This checks availability but only in a linear way, respecting filters order
         * from top to bottom (first to last)
         */
        firstAvailableLinear(slug, value)
        {
            const index = this.filters.findIndex( filter => filter.slug == slug ); 
            const toVerify = this.filters.filter( (filter, i) => i <= index );
            const selections = {}; 

            toVerify.forEach ( filter => {
                selections[filter.slug] = (filter.slug == slug)
                    ? value
                    : this.selections[filter.slug]
            }); 

            return this.results.find( result => {
                for (const key in selections) {
                    if ( !selections[key] ) continue; 

                    if ( !result.optionMatches(key, selections[key]) ) {
                        return false; 
                    }
                }

                return true; 
            }); 
        }, 

        activeValues(filter) {
            return filter.values.filter( value => {
                return this.results.some( result => result.optionMatches(filter.slug, value.value) ); 
            });
        },

        goToNext() {
            this.$emit('nextSection'); 
        }, 

        goToPrev() {
            this.$emit('prevSection'); 
        }, 

        /**
         * This method allows swapping images by the first result with the image. 
         * Most scenarios will just use the same, but this gives us flexibility if we need to 
         * use a different image for a specific set of options. 
         */
        getFilterValueImage(filter, value) {
            const first_available = this.firstAvailable(filter.slug, value.value); 
            const image = first_available?.getOptionImages(filter.slug)[value.value] || null; 

            if ( first_available && image && ![null, ''].includes(image) ) {
                return image; 
            }

            return value.image || null; 
        }
    }


}
</script>
