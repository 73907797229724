<template>
    <div class="sf__part-details" v-if="details">
        <h3>Product Details</h3>
        <ul>
            <li v-for="value, key in details" :key="key">
                <strong>{{ value.label.replace(/_/g, ' ') }}</strong>: {{ value.value }}
            </li>
        </ul>
    </div>
</template>

<script>
import Storage from '../helpers/storage'; 

export default {
    props: ['slug', 'sku'], 

    data() {
        return {
            exceptions: [
                'exception',
                'exception_image',
                'vehicle_tag', 
                'insert_material', 
                'thread_color'
            ],
            details: {}
        }
    }, 

    mounted() {
        this.details = this.getDetails(); 
    },

    methods: {
        getSlug() {
            if ( this.slug ) {
                return this.slug; 
            }

            if ( window.sf_options.collection ) {
                return window.sf_options.collection; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('slug'); 
        },  

        getBuildId() {
            if ( this.build_id ) {
                return this.build_id; 
            }

            if ( window.sf_options.build_id ) {
                return window.sf_options.build_id; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('build_id');  
        }, 

        getDetails() {
            let options = Storage.getPartOptions( this.getBuildId() ); 

            for (const key in options) {
                if (this.exceptions.includes(key) || key.includes('image')) {
                    delete options[key]; 
                }
            }

            return options; 
        }, 

        capitalize(word) {
            return word
                .split('')
                .map((letter, index) =>
                index ? letter.toLowerCase() : letter.toUpperCase(),
                )
                .join('');
        }
    }
}
</script>