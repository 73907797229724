<template>
    <div class="sf__results__result" @click="openOptions">
        <div class="sf__results__result__image">
            <img v-bind:class="{
                'loaded': image_loaded
            }" :src="image" :alt="parts[0].part" @load="image_loaded = true">
        </div>
        <h5 class="sf__results__result__position">{{ parts[0].row }} - {{ parts[0].side }}</h5>
        <h4 class="sf__results__result__title">{{ parts[0].part }}</h4>
        <p class="sf__results__result__sku">&nbsp;</p>
        <p class="sf__results__result__price">&nbsp;</p>
        <button class="sf__results__result__add-to-cart" @click="openOptions">View Options</button>
        <PartGroupModal @onClose="closeOptions" v-if="viewingOptions" :parts="parts" />
    </div>
</template>

<script>
import Part from './Part.vue'; 
import PartGroupModal from './PartGroupModal.vue'; 
import store from '../store';

export default {
    props: ['parts'],

    components: {
        Part, 
        PartGroupModal
    },

    data() {
        return {
            image_loaded: false, 
            viewingOptions: false
        }
    },

    computed: {
        // Let's get the ecommerce image, or we just return the
        // CDN image. 
        image() {
            return store.products[this.parts[0].build_id]?.image
                || this.parts[0].image;
        }
    }, 

    methods: {
        openOptions() {
            this.viewingOptions = true; 
        }, 

        closeOptions() {
            this.viewingOptions = false; 
        }
    }
}
</script>