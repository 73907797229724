<template>
    <div class="sf__filters" v-bind:class="{
        'sf__filters--popup': popup, 
        'sf__filters--show': showFilters
    }">
        <a href="#" class="sf__filters__close" @click.prevent="closeFilters()">&times;</a>
        <div v-if="!started" class="sf__filters__inner">
            <Loader />
        </div>
        <div v-else class="sf__filters__inner">
            <span class="sf__filters__close__popup" @click="closePopup">&times;</span>
            <template v-if="shouldDisplayFilters()">
            <!-- This button allows to reset the entire selection. -->
            <p v-if="!popup">
                <a href="#" class="sf__filters__edit" @click.prevent="reloadPage()">Edit Selection</a>
            </p>

            <template v-for="(section, index) in sections" :key="index">
                <FiltersSection
                    v-if="(popup && currentSectionIndex == index) || (!popup && section.show_after_popup)"
                    :filters="getSectionFilters(section.slug)"
                    :section="section"
                    :index="index"
                    :minIndex="firstSectionIndex"
                    :maxIndex="lastSectionIndex"
                    :totalSections="sections.length"
                    :selections="selections"
                    :stopped="stopped"
                    @select="(selection) => applySelection(selection, section)"
                    @removeOption="removeOption"
                    @nextSection="goToNextSection()"
                    @prevSection="goToPrevSection()"
                    @stopFilters="stopped = true"
                    :results="getFilteredResults(index)"
                    :alerts="alerts"
                    :foam="foam"
                    :popup="popup"
                />
                <FiltersSummary
                    v-if="!popup && !section.show_after_popup"
                    :section="section"
                    :filters="getSectionFilters(section.slug)"
                    :selections="selections"
                    :foam="foam"
                    :alerts="alerts"
                />
            </template>

            </template>
        </div>
    </div>
</template>


<script>
import FiltersSection from './FiltersSection.vue'; 
import FiltersSummary from './FiltersSummary.vue'; 
import Filters from '../helpers/filters'; 
import Loader from './Loader.vue'; 
import store from '../store'; 
import Storage from '../helpers/storage'; 

export default {

    props: ['results', 'popup', 'vehicle', 'foam', 'globalSelections', 'showFilters'],

    components: {
        Loader, 
        FiltersSection, 
        FiltersSummary
    }, 

    data() {
        return {
            // Here the slug can be a single filter slug, or a section slug, that will pull all slugs related 
            // to that section. 
            sections: [
                {slug: 'cab_configuration', title: 'Cab Type', show_after_popup: false, sequential: true}, 
                {slug: 'seat_configuration_1', title: 'Seat Style', show_after_popup: false, sequential: true}, 
                {slug: 'part', title: 'Seat Options', show_after_popup: true, sequential: false}
            ], 
            selections: {},
            firstSectionIndex: 0, 
            lastSectionIndex: 0, 
            currentSectionIndex: 0, 
            filters: [],
            started: false,
            ignored: [], 

            // This is to stop automatic filtering advance after the initial filtering loads. 
            // So on page load, filters advance continuously until one of them changes this flag. 
            // and this allows us to go back on filters and prevent theem from advancing again. 
            stopped: false 
        }
    }, 

    mounted() {
        this.selections = this.globalSelections; 
        this.init(); 
    }, 

    computed: {
        alerts() {
            return store.alerts;
        }
    },

    methods: {
        async init() {

            const filters = await (new Filters(this.results)).build({
                vehicle_id: this.vehicle.id 
            }); 

            // We store filters here so we can use them after without 
            // calling the api again. 
            store.filters = filters; 

            // For the initial filtering, we only pull those filters paired with vehicles or parts. 
            this.filters = filters.filter( filter => {
                return ['vehicle', 'part'].includes(filter.type); 
            }); 

            // We keep this so we can get more info about if we could skip a section or not. 
            this.filters.forEach( filter => {
                let values = this.activeValues(filter);

                // if for this filter there's only one value, we store the alert to show 
                // during summary. 
                store.alerts[filter.slug] = ( values.length === 1)
                    ? {
                        label: filter.label || filter.slug.replace(/_/g, ' '), 
                        message: `The results shown are only available for <strong>${values[0].value}</strong>`, 
                        value: values[0].value,
                        image: values[0].image 
                    } : null;  

                // Here we only need to select filters that could have more than one value. 
                // others just stay as is. 
                if ( values.length > 1 && this.selections[filter.slug] == null) {
                        this.selections[filter.slug] = null; 
                }
            }); 

            // Getting all sections with unfilled values. 
            let unfilled_sections = this.sections.map((section, index) => {
                return {
                    section: section, 
                    index: index // We do this to keep the original index 
                }; 
            }).filter( s => {
                return this.getSectionFilters(s.section.slug).some( f => {
                    return this.activeValues(f).length > 1; //0 && this.selections[f.slug] == null; 
                })
            }).map( (section) => section.index ); 

            // With these values we know what section comes first, 
            // and what section comes last. 
            this.currentSectionIndex = Math.min(...unfilled_sections); 
            this.firstSectionIndex = Math.min(...unfilled_sections);  
            this.lastSectionIndex = Math.max(...unfilled_sections); 

            this.started = true; 

            if ( !Object.values( this.selections ).includes(null) ) {
                this.applyFilters(); 
            }
        },

        
        shouldDisplayFilters() {
            // We only display filters if: 
            // - the popup is opened. 
            // - there are selections to show. 
            // - we're showing foam warnings. 
            return this.popup || Object.keys(this.selections).length > 0 || this.foam; 
        }, 

        /**
         * Gets the filters corresponding to a specific section. 
         */
        getSectionFilters(sectionName) {
            return this.filters.filter( filter => {
                return filter.type == sectionName
                    || filter.slug == sectionName; 
            }); 
        }, 


        /**
         * Applies the selection from the Section. 
         */
        applySelection(selection, section)
        {
            const {slug, value, force} = selection; 

            // 1. if the selection is valid, we just keep it. 
            // 2. if the selection is invalid, we should unselect all other values for that section and 
            // only leave the selected one, giving the customer the chance to reselect everything else again. 
            const desiredSelection = {
                ...this.selections, 
                ...{[slug]: value}
            }; 

            if ( force || this.isValidSelection( desiredSelection ) ) {
                this.selections = desiredSelection; 
            } else {
                if (!confirm('This option is unavailable for your current selection. Selecting this option will clear all selections.')) {
                    return false; 
                }

                // We clear all other section values except for this one. 
                this.getSectionFilters(section.slug).forEach( filter => {
                    if (typeof this.selections[filter.slug] === 'undefined') return; 

                    let values = this.activeValues(filter);
                    this.selections[filter.slug] = ( filter.slug == slug )
                        ? value
                        : (values.length > 1)
                            ? null
                            : values[0].value;  
                });
            }

            // if we're in the results view mode, we just apply filters 
            // after selecting. 
            if ( !this.popup ) this.applyFilters(); 
        }, 

        /**
         * This removes an item from the global selections, useful 
         * when we find that after filtering a specific option is no 
         * longer available. 
         */
        removeOption(selection) {
            delete this.selections[selection.slug]; 
        }, 

        /**
         * Verifies if a selection is valid. 
         */
        isValidSelection(selection) {
            return this.results.filter( result => {
                return ( this.foam ) ? result.isFoam() : true; 
            }).some( result => {
                for (const key in selection) {
                    if ( !selection[key] ) continue;

                    if ( !result.optionMatches(key, selection[key]) ) {
                        return false; 
                    }
                }

                return true; 
            }); 
        }, 

        applyFilters() {
            this.$emit('onApply', {
                selections: this.selections, 
                ignored: this.getIgnoredSelections()
            });
        }, 

        activeValues(filter) {
            return filter.values.filter( value => {
                return this.results.some( result => result.optionMatches(filter.slug, value.value) ); 
            });
        }, 

        allSelectionsMade() {
            return Object.values(this.selections).filter( val => val == null ).length === 0; 
        }, 

        goToNextSection() {
            if ( this.currentSectionIndex == this.lastSectionIndex ) {
                this.applyFilters(); 
            } else {
                this.currentSectionIndex++;
            }
        }, 

        goToPrevSection() {
            this.currentSectionIndex--;
        }, 

        /**
         * This method gets filtered results for the given section
         */
        getFilteredResults(sectionIndex) {
            // For the first section we just return everything. 
            if ( sectionIndex === 0 ) return this.results; 
            let selections = {}; 

            // Let's get active selections for previous sections. 
            this.sections
                .filter ((section, index) => index < sectionIndex )
                .forEach( section => {
                    this.getSectionFilters(section.slug).forEach( filter => {
                        if ( this.selections[filter.slug] != null ) {
                            selections[filter.slug] = this.selections[filter.slug];
                        } 
                    }); 
                }); 

            // And now we only return relevant results that match all those previous selections. 
            return this.results.filter( result => {

                // If this is not a foam search, and the product is foam, it's not useful
                // for our filtering, so we just dismiss it. 
                // if ( !this.foam && result.isFoam() ) {
                //     return false; 
                // }

                for (const key in selections) {
                    if ( !result.optionMatches(key, selections[key]) ) {
                        return false; 
                    }
                }
                return true; 
            });
        },

        reloadPage() {
            // Removes selections from the URL
            const currentSelections = Storage.getSelections(store.collection);
            const queryString = new URLSearchParams(document.location.search);
            Object.keys(currentSelections).forEach(
                key => queryString.delete(key),
            );
            history.pushState(null, null, '?' + queryString.toString());

            Storage.removeSelections( store.collection ); 
            window.location.reload(); 
        },

        /**
         * Ignored selections are those that after filling all selections, these 
         * are left empty despite there are possible values to select from. 
         */
        getIgnoredSelections() {
            // nulled keys in selections. 
            let nulled = Object.keys(this.selections)
                .filter( key => this.selections[key] == null ); 

            // Results that match all the filled selections. 
            let temp_results = this.results.filter( result => {
                for(const key in this.selections) {
                    if ( this.selections[key] == null) continue; 

                    if ( !result.optionMatches(key, this.selections[key]) ) {
                        return false; 
                    }
                }

                return true; 
            }); 

            // this is key ignored if there are no results that would have a non empty 
            // value for that key. (A value that should have been selected via filtering)
            return nulled.filter( key => {
                return !temp_results.some( result => !result.optionIsEmpty(key)); 
            }); 
        },

        closeFilters() {
            this.$emit('closeFilters'); 
        }, 

        closePopup() {
            history.back();
        }
    }
}
</script>
