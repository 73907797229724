<template>
    <div class="sf__results__result" @click="viewProduct(part)">
        <div class="sf__results__result__loading" v-if="!loaded">
            <!-- Loading -->
        </div>
        <div class="sf__results__result__image">
            <img
                v-bind:class="{
                    'loaded': image_loaded
                }"
                v-if="image"
                :src="image"
                :alt="part.title"
                @load="image_loaded = true"
            />
            <p v-else>Image Not Available</p>
        </div>
        <h5 class="sf__results__result__position">{{ part.row }} - {{ part.side }}</h5>
        <h4 class="sf__results__result__title">{{ part.part }}</h4>
        <h5
            class="sf__results__result__subtitle"
            v-if="specific && part.options.option_1_description"
        >
            <span v-if="part.options.option_1_type">{{ part.options.option_1_type }}</span>
            {{ part.options.option_1_description }}
        </h5>
        <p class="sf__results__result__sku">{{ part.sku }}</p>
        <p class="sf__results__result__price">
            <span
                class="sale"
                v-if="part.price_sale < part.price && eligible"
            >{{ money(part.price_sale) }}</span>
            <span class="regular">{{ money(part.price) }}</span>
        </p>
        <button
            :disabled="!available"
            class="sf__results__result__add-to-cart"
            @click.stop="addToCart(part)"
            v-bind:class="{
                'disabled': !available, 
                'hidden': !loaded
            }"
        >
            <span v-if="available">
                <span v-if="adding">
                    &nbsp;
                    <div class="sf__results__result__add-to-cart__loader"></div>
                </span>
                <span v-else>Add to Cart</span>
            </span>
            <span v-else>Out of Stock</span>
        </button>
    </div>
</template>

<style lang="scss">

</style>

<script>
import store from '../store';
import Storage from '../helpers/storage';

export default {
    props: ['part', 'specific'],

    data() {
        return {
            image_loaded: false,
            adding: false
        }
    },

    computed: {
        loaded() {
            return store.products[this.part.build_id] != null;
        },

        available() {
            return store.products[this.part.build_id]?.available || false;
        },

        image() {
            const image = store.products[this.part.build_id]?.image
                || this.part.image;

            if (this.specific) {
                return this.part.options.option_1_type_image || image; 
            }

            return image; 
        },

        eligible() {
            return store.products[this.part.build_id]?.eligible;
        }
    },

    methods: {
        viewProduct(part) {
            if (!this.loaded) return false;

            this.storeRecommendationsInStorage();
            part.view();
        },

        async addToCart(part) {
            if (!this.available || !this.loaded) return false;

            this.adding = true;
            try {
                let response = await part.addToCart();
                if (typeof window.sf_options?.afterAddToCart === 'function') {
                    this.storeRecommendationsInStorage();
                    window.sf_options.afterAddToCart(response);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.adding = false;
            }
        },

        storeRecommendationsInStorage() {
            Storage.storeRecommendations(this.part.build_id, store.results_page);
        },

        money(value) {
            if (!value) return null;
            return '$' + parseFloat(value).toFixed(2);
        }
    }
}
</script>
