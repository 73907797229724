<template>
    <div class="sf__vehicles__options">
        <div class="sf__vehicles__option">
            <select v-bind:class="{ 'selected': selections.year != '', 'errored': selections.year == '' && showError }" name="year" id="" v-model="selections.year" :disabled="dropdowns.year.length < 1">
                <option value="">{{ yearLabel || 'Select Year' }}</option>
                <option v-for="year in dropdowns.year" :key="year.key" :value="year.key">{{ year.value }}</option>
            </select>
        </div>

        <div class="sf__vehicles__option">
            <select v-bind:class="{ 'selected': selections.make != '', 'errored': selections.make == '' && showError }" name="make" id="" v-model="selections.make" :disabled="dropdowns.make.length < 1">
                <option value="">{{ makeLabel || 'Select Make' }}</option>
                <option v-for="make in dropdowns.make" :key="make.key" :value="make.key">{{ make.value }}</option>
            </select>
        </div>


        <div class="sf__vehicles__option">
            <select v-bind:class="{ 'selected': selections.model != '', 'errored': selections.model == '' && showError }" name="model" id="" v-model="selections.model" :disabled="dropdowns.model.length < 1">
                <option value="">{{ modelLabel || 'Select Model' }}</option>
                <option v-for="model in dropdowns.model" :key="model.key" :value="model.key">{{ model.value }}</option>
            </select>
        </div>


        <div class="sf__vehicles__option">
            <select v-bind:class="{ 'selected': selections.submodel != '', 'errored': selections.submodel == '' && showError }" name="submodel" id="" v-model="selections.submodel" :disabled="dropdowns.submodel.length < 1">
                <option value="">{{ submodelLabel || 'Select Submodel'}}</option>
                <option v-for="submodel in dropdowns.submodel" :key="submodel.key" :value="submodel.key">{{ submodel.value }}</option>
            </select>
        </div>

        <button @click="search">{{ buttonText || 'View Products' }}</button>
    </div>
    <p v-if="showError" class="sf__vehicles__error">You need to select all of the options to continue.</p>
</template>

<script>
import {range} from 'lodash'; 
import Ecommerce from '../adapters/Ecommerce'; 
import Searcher from '../helpers/searcher'; 
import Pluralize from 'pluralize'; 
import {slugify} from '../helpers/helpers';
import storage from '../helpers/storage';

const Platform = Ecommerce.getInstance(window.sf_options.platform); 

export default {

    props: ['foam', 'videos', 'buttonText', 'yearLabel', 'makeLabel', 'modelLabel', 'submodelLabel'], 

    data() {
        return {
            started: false, 
            showError: false,
            dropdowns: {
                year: [], 
                make: [], 
                model: [],
                submodel: []
            },
            selections:{
                year: '', 
                make: '', 
                model: '', 
                submodel: ''
            }
        }
    },


    watch: {
        'selections.year': function(newval) {
            if ( newval != '' && this.started) {
                this.pullDropdowns('make'); 
            }
        }, 

        'selections.make': function(newval) {
            if ( newval != '' && this.started) {
                this.pullDropdowns('model'); 
            }
        },

        'selections.model': function(newval) {
            if ( newval != '' && this.started) {
                this.pullDropdowns('submodel'); 
            }
        }, 

        'selections': {
            deep: true, 
            handler(newVal) {
                if (!Object.values(this.selections).includes('') && this.showError ) {
                    this.showError = false; 
                }
            }
        }
    }, 


    computed: {
        can_continue() {
            return this.selections.year != '' && this.selections.make != '' && this.selections.model != '' && this.selections.submodel != ''; 
        }
    }, 


    async mounted() {
        const currentSelections = storage.getLastVehicleSelection(); 

        if (currentSelections) {
            this.dropdowns = {...currentSelections.dropdowns};
            this.$nextTick(() => {
                this.selections = {...currentSelections.selections};
                
                this.$nextTick(() => {
                    this.started = true; 
                });
            });
        } else {
            this.getYears(); 
            this.started = true; 
        }
    },

    methods: {

        async pullDropdowns(facet) {

            // First we null all n+1 options after the first empty. 
            let facetIndex = ['year', 'make', 'model', 'submodel'].findIndex( (key) => key == facet ); 

            // Emptying all n+1 selections and dropdowns. 
            ['year', 'make', 'model', 'submodel'].forEach((f, index) => {
                if ( index >= facetIndex) {
                    this.selections[f] = ''; 
                    this.dropdowns[f] = []; 
                }
            })

            let {facets} = await Searcher.search( this.isFoam() ? 'foam durofoam' : '*', {
                optionalWords: this.isFoam() ? 'foam durofoam' : null, 
                filters: Searcher.buildFilters( this.selections ),
                facets: [`vehicle.${facet}`], 
            });
            
            if (Object.keys(facets).length === 0 && facet == 'submodel') {
                this.dropdowns[facet] = [
                    {key: '', value: `All available ${Pluralize(facet)}` }
                ]
            } else {
                this.dropdowns[facet] = Object.keys(facets[`vehicle.${facet}`]).sort().map (val => {
                    return {key: val, value: val}
                });
            }

            return this.dropdowns[facet]; 
        }, 


        async getYears() {

            let {facets} = await Searcher.search( this.isFoam() ? 'foam durofoam' : '*', {
                optionalWords: this.isFoam() ? 'foam durofoam' : null, 
                facets: ['vehicle.start_year', 'vehicle.end_year']
            }); 

            let years = range(
                parseInt(Object.keys(facets['vehicle.start_year']).sort()[0]), 
                parseInt(Object.keys(facets['vehicle.end_year']).sort().reverse()[0]) + 1, // We add the +1 because the range method doesn't include the end value.  
            ); 

            this.dropdowns['year'] = years.map( year => {
                return { key: year, value: year }; 
            }); 

            return; 
        }, 


        async search() {

            if (!this.can_continue) {
                this.showError = true; 
                if ( typeof window?.sf_options?.onYmmsError === 'function') {
                    window.sf_options.onYmmsError(); 
                }
                return; 
            } else {
                this.showError = false; 
            }

            if ( typeof window?.sf_options?.onYmmsSearch === 'function') {
                window.sf_options.onYmmsSearch(); 
            }

            let {hits} = await Searcher.search( this.isFoam() ? 'foam durofoam' : '*', {
                optionalWords: this.isFoam() ? 'foam durofoam' : null, 
                filters: Searcher.buildFilters(this.selections)
            });

            storage.storeLastVehicleSelection({
                selections: this.selections, 
                dropdowns: this.dropdowns
            }); 

            let params = { ...this.selections }; 

            if (this.isVideos()) {
                return this.$emit('vehicleSelected', params); 
            }

            Platform.goToPartResults(slugify(
                Object.values(params).join('-')
            ), {
                ...params, 
                ...{ foam: this.isFoam() }
            }); 
        }, 



        isFoam() {
            if ( this.foam ) {
                return true; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('foam') == 'true' ? true : false; 
        }, 

        isVideos() {
            if ( this.videos ) {
                return true; 
            }

            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('videos') ? true : false; 
        },
    }
}

</script>


