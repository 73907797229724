<template>
    <vehicles buttonText="View Videos" :videos="true" @vehicleSelected="getVideos" />
    <template v-if="started">
        <loader v-if="loading" />
        <div v-if="!loading && results.length > 0" class="sf__install-videos">
            <InstallVideo :video="video" v-for="(video, index) in results" :key="index"/>
        </div>
        <div v-if="!loading && results.length == 0" class="sf__install-videos">
            <p>There are no videos matching your search. Please adjust the filters and try again.</p>
        </div>
    </template>
</template>

<script>
import Vehicles from './Vehicles.vue'
import InstallVideo from './InstallVideo.vue'; 
import Searcher from '../helpers/searcher'; 
import Loader from './Loader.vue';
export default {
  components: { Vehicles, InstallVideo, Loader },

  data() {
      return {
          results: [], 
          loading: false,
          started: false 
      }
  }, 

  methods: {
      async getVideos(params) {

            this.loading = true; 
            // Here we could paginate and load everything. 
            const {hits} = await Searcher.search( '*', {
                filters: Searcher.buildFilters( params ), 
                hitsPerPage: 1000
            });

            this.results = []; 

            hits.forEach(hit => {
                Object.keys(hit.part.additional_data).forEach( key => {
                    if (
                        key.includes('install_video_url')
                        && ![null, ''].includes(hit.part.additional_data[key])
                        && !this.videoExists(hit.part.additional_data[key])) {
                        this.results.push({
                            id: hit.part.additional_data[key].split('/').pop(), 
                            video: hit.part.additional_data[key], 
                            title: `${hit.vehicle.collection_name} ${hit.options.row} ${hit.options.side} ${hit.options.part}`
                        }); 
                    }
                }); 
            }); 

            this.loading = false; 
            this.started = true; 
      }, 

      videoExists(url) {
          return this.results.some( video => video.video == url);
      }
  }

}
</script>