<template>
    <div class="sf__install-video" @click.prevent="opened = true">
        <div class="sf__install-video__image">
            <img :src="getVideoImage()" :alt="oembed.title">
        </div>
        <h4 class="sf__install-video__title">{{ oembed.title }}</h4>
    </div>
    <InstallVideoModal :video="video" v-if="opened" @close="opened = false"/>
</template>


<script>
import InstallVideoModal from './InstallVideoModal.vue'; 

export default {
    props: ['video'], 

    components: {
        InstallVideoModal
    }, 

    data() {
        return {
            opened: false, 
            oembed: {}
        }
    }, 

    mounted() {
        fetch('https://www.youtube.com/oembed?url=' + encodeURIComponent(this.video.video))
            .then( response => response.json() )
            .then( response => {
                this.oembed = response; 
            }); 
    }, 

    computed: {
        video_url() {
            return 'https://www.youtube.com/embed/' + this.video.id; 
        }
    }, 

    methods: {
        getVideoImage() {
            return `https://img.youtube.com/vi/${this.video.id}/hqdefault.jpg`; 
        }
    }
}
</script>