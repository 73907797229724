import merges from '../helpers/merges'; 
class Result {

    constructor(data, vehicle) {
        Object.assign(this, data); 
        this.vehicle = vehicle; 
    }

    /**
     * returns the value for a given option. 
     * 
     * @param {string} slug 
     * @returns {string|null} the value, or null if not present. 
     */
    getOption(slug) {
        let value = this.options[slug]; 

        if ( typeof value === 'undefined') {
            console.error(`The option ${slug}  is undefined. This is something that should be fixed. Object ID: ${this.objectID}`); 
            return null; 
        }

        if ([null, ''].includes(value)) {
            return null; 
        }

        return value; 
    }


    getOptionImages(slug) {

        if ( merges[slug] != null ) {
            return merges[slug].reduce ((acc, s) => {
                acc[ this.getOption(s) ] = this.getOption(`${s}_image`); 
                return acc; 
            }, {}); 
        }

        return { [this.getOption(slug)]: this.getOption(`${slug}_image`)}; 
    }

    
    optionMatches(slug, value) {

        // If the option matches, we search by seat configuration.
        if ( merges[slug] != null ) {
            return Object.keys( this.options )
                .filter( key => merges[slug].includes(key) )
                .map ( key => this.getOption(key) )
                .includes( value ); 
        }

        return this.getOption(slug) == value; 
    }


    optionIsEmpty(slug) {
        return this.getOption(slug) == null; 
    }


    isFoam() {
        return this.getOption('part').toLowerCase().includes('foam'); 
    }
}


export default Result; 