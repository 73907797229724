import Shopify from './shopify'; 
import Local from './local'; 
const Ecommerce = {
    
    getInstance: () => {

        if ( window.sf_options?.platform == null ) {
            throw Exception('The ecommerce platform is not defined'); 
        }

        switch( window.sf_options.platform ) {
            case 'shopify': 
                return Shopify; 
                break; 
            case 'local': 
                return Local; 
                break; 
        }
    }
}

export default Ecommerce; 